<script setup lang="ts">
  import { ChevronDownIcon } from '@heroicons/vue/solid';
  import SupportIcon from '~/assets/Icons/SupportIcon.vue';

  const handleLogout = () => {
    console.log('logout!');
  };
</script>

<template>
  <a-menu-item class="py-2" key="dashboard">
    <nuxt-link to="/">Dashboard</nuxt-link>
  </a-menu-item>
  <a-menu-item class="py-2" key="expenses">
    <nuxt-link to="/expenses">Expenses</nuxt-link>
  </a-menu-item>
  <a-menu-item class="py-2" key="advances">
    <nuxt-link to="/advances">Advances</nuxt-link>
  </a-menu-item>
  <a-menu-item class="py-2" key="contacts">
    <a-dropdown>
      <a class="ant-dropdown-link">
        <div class="flex gap-1 items-center">
          <p>Contacts</p>
          <ChevronDownIcon class="h-5 w-5 text-zinc-500" />
        </div>
      </a>
      <template #overlay>
        <a-menu class="w-[150px] !rounded-t-none !mt-1">
          <a-menu-item>
            <nuxt-link to="/donors" class="sm-medium">Donors</nuxt-link>
          </a-menu-item>
          <a-menu-item>
            <nuxt-link to="/vendors" class="sm-medium">Vendors</nuxt-link>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </a-menu-item>
  <a-menu-item class="py-2" key="donations">
    <nuxt-link to="/donations">Donations</nuxt-link>
  </a-menu-item>
  <a-menu-item class="py-2" key="grants">
    <nuxt-link to="/grants">Grants</nuxt-link>
  </a-menu-item>
  <a-menu-item class="py-2" key="pledges">
    <nuxt-link to="/pledges">Pledges</nuxt-link>
  </a-menu-item>
  <a-menu-item class="py-2" key="budgets">
    <nuxt-link to="/budgets">Budgets</nuxt-link>
  </a-menu-item>
  <a-menu-item key="utilities" class="!ml-auto py-2">
    <nuxt-link to="/utilities">Utilities</nuxt-link>
  </a-menu-item>
  <a-menu-item class="py-2" key="setup">
    <nuxt-link to="/setup">Setup</nuxt-link>
  </a-menu-item>
  <a-menu-item class="py-2" key="admin">
    <nuxt-link to="/admin">Admin</nuxt-link>
  </a-menu-item>
  <a-menu-item class="py-2" key="Support">
    <nuxt-link to="/support" class="flex items-center justify-between gap-2">
      <SupportIcon />
      <p>Support</p>
    </nuxt-link>
  </a-menu-item>
  <a-menu-item class="py-2" @click="handleLogout">Logout</a-menu-item>
</template>
