import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export type { Dayjs as dateTimeType };

export function getToday(): Dayjs {
  return dayjs();
}

export function toDateTime(date: string | Date | Dayjs): Dayjs {
  return dayjs(date);
}

export function checkValidDate(date: string | Date | Dayjs): boolean {
  return dayjs(date).isValid();
}

export function formatDate(date: string | Date, format: string = 'DD MMM YYYY'): string {
  return dayjs(date).format(format);
}

export function getTodayFormatted(format: string = 'DD MMM YYYY'): string {
  return dayjs().format(format);
}

export function addDays(date: string | Date, days: number, format: string = 'DD MMM YYYY'): string {
  return dayjs(date).add(days, 'day').format(format);
}

export function subtractDays(date: string | Date, days: number, format: string = 'DD MMM YYYY'): string {
  return dayjs(date).subtract(days, 'day').format(format);
}

export function isAfter(date1: string | Date, date2: string | Date): boolean {
  return dayjs(date1).isAfter(date2);
}

export function isBefore(date1: string | Date, date2: string | Date): boolean {
  return dayjs(date1).isBefore(date2);
}

export function getCurrentYear(): number {
  return dayjs().year();
}

export function getEndOfDay(): Dayjs {
  return dayjs().endOf('day');
}

export function getMonthName(monthIndex: number): string {
  return dayjs().month(monthIndex).format('MMM');
}

export function getStartOfDayISOString(date: string | Date | Dayjs): string {
  return dayjs(date).startOf('day').toISOString();
}
