import { captureException } from '@sentry/vue';
import type { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';

// Function to get the API instance
const getApi = (): AxiosInstance => {
  const nuxtApp = useNuxtApp();
  return nuxtApp.$api;
};

// Error handling function
const handleError = (error: any): any => {
  console.error('API request failed:', error);
  captureException(error);
};

// GET request function
export const get = async <T>(endpoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  try {
    const api = getApi();
    const response: AxiosResponse<T> = await api.get(endpoint, config);
    return {
      headers: response.headers,
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      config: response.config
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getAttachment = async (
  table: string,
  identifier: string,
  fileName: string
): Promise<AxiosResponse<ArrayBuffer>> => {
  const response = await get<ArrayBuffer>(`v1/attachments/${table}-${identifier}-${fileName}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  });

  return response; // Return the full Axios response
};

export const getReceipt = async (identifier: string): Promise<AxiosResponse<ArrayBuffer>> => {
  const response = await get<ArrayBuffer>(`v1/donation_receipts/${identifier}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  });

  return response; // Return the full Axios response
};
// POST request function
export const post = async <T>(endpoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  try {
    const api = getApi();
    const response: AxiosResponse<T> = await api.post(endpoint, config); // Pass FormData directly
    return {
      headers: response.headers,
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      config: response.config
    };
  } catch (error) {
    return handleError(error);
  }
};

export const postForm = async <T>(endpoint: string, formData: any): Promise<AxiosResponse<T>> => {
  try {
    const api = getApi();
    const response: AxiosResponse<T> = await api.postForm(endpoint, formData);
    return {
      headers: response.headers,
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      config: response.config
    };
  } catch (error) {
    return handleError(error);
  }
};

export const put = async <T>(endpoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  try {
    const api = getApi();
    const response: AxiosResponse<T> = await api.put(endpoint, config); // Pass FormData directly
    return {
      headers: response.headers,
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      config: response.config
    };
  } catch (error) {
    return handleError(error);
  }
};

export const putForm = async <T>(endpoint: string, formData: any): Promise<AxiosResponse<T>> => {
  try {
    const api = getApi();
    const response: AxiosResponse<T> = await api.putForm(endpoint, formData); // Pass FormData directly
    return {
      headers: response.headers,
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      config: response.config
    };
  } catch (error) {
    return handleError(error);
  }
};

export const del = async <T>(endpoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  try {
    const api = getApi();
    const response: AxiosResponse<T> = await api.delete(endpoint, config);
    return {
      headers: response.headers,
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      config: response.config
    };
  } catch (error) {
    return handleError(error);
  }
};
