<script setup lang="tsx">
  import MonetaryDonationDrawer from './RoutedDrawerCollection/MonetaryDonationDrawer.vue';
  import InKindDonationDrawer from './RoutedDrawerCollection/InKindDonationDrawer.vue';
  import DonorsDrawer from './RoutedDrawerCollection/DonorsDrawer.vue';

  const identifier = ref<string | null>(null);
  const drawerFrom = ref<string | null>(null);

  const route = useRoute();
  const router = useRouter();

  const onClose = () => {
    const { drawerFrom, identifier, ...rest } = route.query;
    router.replace({ query: { ...rest } });
  };

  const openModal = (modalType: string, identifier: string) => {
    const { drawerFrom, ...restQuery } = route.query;
    router.replace({
      query: {
        ...restQuery,
        modalFor: modalType,
        identifier: identifier
      }
    });
  };

  watch(
    () => route.query,
    newQuery => {
      if (newQuery.drawerFrom && newQuery.identifier) {
        identifier.value = newQuery.identifier as string;
        drawerFrom.value = newQuery.drawerFrom as string;
      } else {
        identifier.value = null;
        drawerFrom.value = null;
      }
    },
    { immediate: true, deep: true }
  ); //set drawer if route has drawerFrom and identifier
</script>

<template>
  <MonetaryDonationDrawer
    v-if="drawerFrom === 'monetaryDonations'"
    @close="onClose"
    :openModal="openModal"
    :identifier="identifier ?? ''"
  />
  <InKindDonationDrawer
    v-if="drawerFrom === 'inKindDonations'"
    @close="onClose"
    :openModal="openModal"
    :identifier="identifier ?? ''"
  />
  <DonorsDrawer v-if="drawerFrom === 'donors'" @close="onClose" :openModal="openModal" :identifier="identifier ?? ''" />
</template>
