<script setup lang="ts">
  import MenuItems from './MenuItems.vue';

  const route = useRoute();

  const selectedKeys = computed(() => [
    route.path === '/'
      ? 'dashboard'
      : route.path.startsWith('/donors') || route.path.startsWith('/vendors')
        ? 'contacts'
        : route.path.slice(1)
  ]);
</script>

<template>
  <div
    class="flex items-center pl-6 pr-4 border-b border-zinc-100 shadow-[0_1px_3px_0px_#00000003] sticky top-0 z-[51] bg-white"
  >
    <div class="border hover:cursor-default border-[#00336B] flex justify-center items-center px-2 py-1 h-fit">
      <span class="text-[#00336B] text-sm font-medium">ERP4</span>
      <span class="text-[#6A6E05] text-sm font-normal">Impact</span>
    </div>
    <a-menu
      mode="horizontal"
      class="w-full flex items-center text-zinc-500 font-medium !border-0"
      :selected-keys="selectedKeys"
    >
      <MenuItems />
    </a-menu>
  </div>
</template>
