import {
  openSuccessfullyAddedNotification,
  openSuccessfullyUpdatedNotification,
  openSuccessfullyDeletedNotification,
  openFailedAdditionNotification,
  openFailedUpdationNotification,
  openFailedDeletionNotification,
  openSuccessfulCustomNotification,
  openFailedCustomNotification
} from '@/utils/notification';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.provide('succesfullyAddedNotification', openSuccessfullyAddedNotification);
  nuxtApp.provide('succesfullyUpdatedNotification', openSuccessfullyUpdatedNotification);
  nuxtApp.provide('succesfullyDeletedNotification', openSuccessfullyDeletedNotification);
  nuxtApp.provide('successfulCustomNotification', openSuccessfulCustomNotification);
  nuxtApp.provide('failedAddNotification', openFailedAdditionNotification);
  nuxtApp.provide('failedUpdateNotification', openFailedUpdationNotification);
  nuxtApp.provide('failedDeleteNotification', openFailedDeletionNotification);
  nuxtApp.provide('failedCustomNotification', openFailedCustomNotification);
});
