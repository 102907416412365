<script setup lang="ts">
  const props = defineProps<{
    title: string;
  }>();

  const activeKey = ref(['1']);
</script>

<template>
  <a-collapse expandIconPosition="end" v-model:activeKey="activeKey">
    <a-collapse-panel key="1" :header="title">
      <slot></slot>
    </a-collapse-panel>
  </a-collapse>
</template>
