<script setup>
  const props = defineProps({
    text: {
      type: String,
      required: true
    }
  });
  const isExpanded = ref(false);
  const maxChars = 150;

  const truncatedText = computed(() => {
    return props.text.length > maxChars ? props.text.slice(0, maxChars) + '...' : props.text;
  });

  const shouldTruncate = computed(() => {
    return props.text.length > maxChars;
  });

  const toggleExpand = () => {
    isExpanded.value = !isExpanded.value;
  };
</script>

<template>
  <div>
    <span>{{ isExpanded ? text : truncatedText }}</span>
    <button v-if="shouldTruncate" @click="toggleExpand">
      {{ isExpanded ? 'Show Less' : 'Show More' }}
    </button>
  </div>
</template>
